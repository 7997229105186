<template>
  <main>
    <div class="top-view">
      <img class="top-view__bg" src="../../assets/img/bg_06@2x.jpg" alt="">
      <img class="top-view__title" src="../../assets/img/pic_17@2x.png" alt="实现财务自由，您需要有人生规划">
    </div>

    <!--人生八大规划-->
    <section class="section-1">
      <shadow-title2 en="Eight Life Plans" cn="人生八大规划" class="section-1__title"/>
      <p class="section-1__text">运用科学的方法和特定的程序为客户制定切合实际、具有可操作性的方案，使客户不断提高生活质量，最终达到终生财务的自主和自由。</p>
      <div class="section-1__block-container">
        <div class="section-1__block-item" v-for="item in plans.slice(0, 4)" :key="item.cn">
          <img :src="item.img" :alt="item.cn">
          <h3>{{item.cn}}</h3>
          <p>{{item.en}}</p>
        </div>
      </div>
      <!--拆开两个分别flex-->
      <div class="section-1__block-container">
        <div class="section-1__block-item" v-for="item in plans.slice(4)" :key="item.cn">
          <img :src="item.img" :alt="item.cn">
          <h3>{{item.cn}}</h3>
          <p>{{item.en}}</p>
        </div>
      </div>
    </section>

    <!--人生规划四部曲-->
    <section class="section-2">
      <shadow-title2 en="Life Planning Tetralogy" cn="人生规划四部曲" class="section-2__title"/>
      <p class="section-2__text">人生规划四部曲(LPPP)即学习(Learning)、规划(Planning)、建议(Proposing)和实践(Practice)。</p>
      <p class="section-2__text">透过人生规划四部曲，协助您有效执行人生八大规划，实现财务自由。</p>
      <div class="section-2__block-container">
        <div class="section-2__block-item" v-for="item in four" :key="item.first">
          <i></i>
          <span>{{item.first}}</span>
          <h3>{{item.second}}</h3>
          <h4>{{item.third}}</h4>
          <p>{{item.fourth}}</p>
        </div>
      </div>
    </section>

    <!--???-->
    <section class="section-3">
      <img src="../../assets/img/pic_18@2x.png" alt="">
      <div class="section-3__text">
        <h3>高端企业家如何实现财务自由</h3>
        <h4>我们透过乐享FP专才和企服专家为企业家的您打造专属的人生规划四部曲ELPPP</h4>
        <p>协助企业家有效地隔离家庭与企业的资产，在企业得以发展的同时，家庭也得到全面的人生规划与保障。</p>
        <p>提供专业企业金融咨询，优化和完善税务规划、现金和债务规划、合理改善股权及融资结构、提升企业价值。</p>
        <p>量身定做全面资产配置，妥善搭配固定和流动资产，运用各类适合的工具进行风险管理，以保证资产稳健增值。</p>
        <p>制定顶级国际化家族传承方案，协助企业家规划传承，确保资产安全稳妥地按企业家的意愿去执行安排，以实现代代相传。</p>
      </div>
    </section>
    <!--???2-->
    <section class="section-4">
      <div class="section-4__text">
        <h3>我们透过专业的FP专才</h3>
        <h3>为客户提供LPPP和ELPPP</h3>
        <p>协助一般客户和企业家客户做好
          <span>专业的人生规划</span>
        </p>
        <p>
          让个人、家庭有效地
          <span>实现财务自由</span>
        </p>
        <p>
          让企业家的资产得到保障和传承
          <span>真正达到“富过三代”</span>
        </p>
      </div>
      <img src="../../assets/img/pic_19@2x.png" alt="">
    </section>
  </main>
</template>

<script>
  import ShadowTitle2 from "../../components/common/ShadowTitle2"
  export default {
    name: "Service",
    components: {
      ShadowTitle2
    },
    metaInfo: {
      title: '乐享无限官网-产品服务',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限产品服务,乐享无限官网,乐享无限综合性自我增值平台,智菁通,财务规划',
        },
        {
          name: 'description',
          content: '乐享无限提供的产品和服务，是运用科学的方法和特定的程序,为客户制定切合实际且具有可操作性的方案,使客户不断提高生活质量,最终达到终生财务的自主和自由。'
        }
      ]
    },
    data() {
      return {
        plans: [
          {
            img: require('../../assets/img/icon_retirement@2x.png'),
            cn: '养老规划',
            en: 'Retirement Planning'
          },
          {
            img: require('../../assets/img/icon_education@2x.png'),
            cn: '教育规划',
            en: 'Education Planning'
          },
          {
            img: require('../../assets/img/icon_protection@2x.png'),
            cn: '保障规划',
            en: 'Protection Planning'
          },
          {
            img: require('../../assets/img/icon_investment@2x.png'),
            cn: '投资规划',
            en: 'Investment Planning'
          },
          {
            img: require('../../assets/img/icon_estate@2x.png'),
            cn: '传承规划',
            en: 'Estate Planning'
          },
          {
            img: require('../../assets/img/icon_tax@2x.png'),
            cn: '税务规划',
            en: 'Tax Planning'
          },
          {
            img: require('../../assets/img/icon_cash@2x.png'),
            cn: '现金规划',
            en: 'Cash Flow Planning'
          },
          {
            img: require('../../assets/img/icon_debt@2x.png'),
            cn: '债务规划',
            en: 'Debt Planning'
          },
        ],
        four: [
          {
            first: '第一部曲',
            second: '学习 Learning',
            third: '知识改变命运',
            fourth: '透过乐享无限APP线上FP专才的分享，线下专家的专题活动， 让您学习人生八大规划，从而洞察自身的财务盲点，修正错误观念，形成正确思维，养成良好习惯，继而改变命运。'
          },
          {
            first: '第二部曲',
            second: '规划 Planning',
            third: '量身定制',
            fourth: '由专业训练有素的FP专才针对您不同的背景，如年龄、收入、工作、爱好等实际情况，为您量身定制人生必须的八大规划。'
          },
          {
            first: '第三部曲',
            second: '建议 Proposing',
            third: '优选之选',
            fourth: '专业独立的FP专才从中立的角度为您进行客观分析，审慎比较市场各类工具，筛选合适的解决方案，协助您达成八大规划的实施。'
          },
          {
            first: '第四部曲',
            second: '实践 Practice',
            third: '终身财富管家',
            fourth: '通过专业的FP终身服务，协助您执行八大规划，并与时俱进地跟进您家庭的成长，及时关注市场的变化，为您作出适宜的指引和调整，最终实现财务自由。'
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
main {
  text-align: center;
}
.top-view {
  position: relative;
}
.top-view__bg {
  width: 100%;
  display: block;
}
.top-view__title {
  position: absolute;
  bottom: 36.5%;
  left: 50%;
  transform: translateX(-50%);
}

.section-1 {
  &__title {
    margin: 80px 0 50px;
  }
}
.section-1__text {
  max-width: 1340px;
  width: 90%;
  margin: 50px auto;
  font-size: 18px;
  color: #333333;
}
.section-1__block-container {
  max-width: 1340px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section-1__block-item {
  height: 260px;
  width: 240px;
  box-shadow: $shadow;
  @include clearfix;
  margin-bottom: 50px;

  img {
    display: block;
    margin: 45px auto;
  }
  h3 {
    font-size: 18px;
    line-height: 1em;
    font-weight: normal;
    margin-bottom: 14px;
    color: #333333;
  }
  p {
    font-size: 18px;
    color: #999999;
  }
}

// 四部曲
.section-2 {
  height: 970px;
  width: 100%;
  background: url("../../assets/img/bg_08@2x.jpg") no-repeat center center;
  background-size: cover;
  @include clearfix;
  .section-2__title {
    ::v-deep .shadow-title__cn {
      color: #ffffff;
    }
    ::v-deep .shadow-title__line {
      background: #ffffff;
    }
    margin: 50px 0;
  }
}
.section-2__text {
  font-size: 18px;
  color: #ffffff;
}
.section-2__block-container {
  display: flex;
  width: 910px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px auto 0;
}
.section-2__block-item {
  width: 410px;
  height: 300px;
  margin-bottom: 50px;
  background: url("../../assets/img/bg_07@2x.png");
  color: #333333;
  @include clearfix;
  box-sizing: border-box;
  padding: 0 25px;
  text-align: justify;
  i {
    display: block;
    height: 5px;
    width: 40px;
    background: #f39800;
    margin-top: 30px;
  }
  span {
    display: block;
    font-size: 18px;
    line-height: 18px;
    margin: 20px 0;
  }
  h3 {
    font-size: 30px;
    line-height: 30px;
    font-weight: normal;
  }
  h4 {
    font-size: 28px;
    line-height: 1em;
    margin: 18px 0 22px;
  }
  p {
    font-size: 16px;
  }
}
.section-3,
.section-4 {
  width: 1140px;
  margin: 0 auto;
  @include clearfix;
  text-align: left;
  color: #333333;
  img {
    float: left;
    box-shadow: 30px 30px 0 0px #f39800;
  }
}
.section-3 {
  margin-top: 80px;
}
.section-3__text {
  float: left;
  width: 500px;
  margin-left: 180px;
  h3 {
    margin-top: 80px;
    font-size: 36px;
    line-height: 30px;
  }
  h4 {
    margin: 30px 0 100px;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: normal;
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 20px;
      background: #f39800;
      left: 0;
      top: 4px;
    }
  }
}
.section-4 {
  margin-top: 50px + 30px;
  padding-bottom: 100px;
}
.section-4__text {
  float: left;
  width: 500px;
  margin-right: 150px;
  h3 {
    font-size: 36px;
    &:nth-child(1) {
      margin-top: 120px;
    }
    &:nth-child(2) {
      margin-bottom: 50px;
    }
  }
  p {
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 40px;
    span {
      margin-left: 30px;
      font-size: 24px;
      font-weight: bold;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border-top: 4px solid #f39800;
        border-right: 4px solid #f39800;
        right: -16px;
        top: -12px;
      }
    }
  }
}
</style>
