<template>
  <div class="shadow-title">
    <h2 class="shadow-title__cn">{{cn}}</h2>
    <h2 class="shadow-title__en">{{en}}</h2>
    <div class="shadow-title__line"></div>
  </div>
</template>

<script>
  export default {
    name: "ShadowTitle",
    props: {
      cn: String,
      en: String,
    }
  }
</script>

<style lang="scss" scoped>
.shadow-title {
  &__cn {
    color: #333333;
    font-size: 36px;
    position: relative;
  }
  &__en {
    color: #999999;
    font-size: 36px;
    margin-top: -0.4em;
    font-weight: normal;
  }
  &__line {
    width: 60px;
    height: 2px;
    background: #333333;
    margin: 15px auto 0;
  }
}

</style>
